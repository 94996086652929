import axios from "axios";
import axiosJsonpAdapter from "axios-jsonp";
import { EXTERNAL_API_URL } from "@/const";

export default {
  data() {
    return {
      isAddressSelectModalShow: false,
      addressResults: [], // 住所検索モーダル内で使用
      selectedAddress: {}, // 【企業情報】住所検索結果（モーダル展開時はモーダル内選択結果）
      selectedInvoiceAddress: {}, // 【請求先】住所検索結果（モーダル展開時はモーダル内選択結果）
    };
  },

  methods: {
    /**
     * 住所検索押下
     */
    searchAddressHandler(formId, factoryId, storeId) {
      if (factoryId === 0) {
        this.targetForm = this.form.generateCompanyInfoList.filter(
          (list) => list.formId === formId
        )[0];
      } else {
        this.targetForm = this.form.generateCompanyInfoList[formId - 1]
          .generateFactoryInfoList[factoryId - 1]
          .generateStoreInfoList.filter(
            (list) => list.storeId === storeId
          )[0];
      }
      const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${this.targetForm.addressInfo.zipCode}`;

      axios
        .get(url, { adapter: axiosJsonpAdapter })
        .then((res) => {
          const results = res.data.results;

          if (results === null) {
            alert("該当住所が見つかりません、郵便番号をご確認ください。");
          } else if (results.length === 1) {
            // 単一住所のみヒット
            this.selectedAddress = results[0];
            this.updateFormVal(this.targetForm);
          } else {
            // 復数住所のヒット
            this.addressResults = results;
            this.isAddressSelectModalShow = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    updateFormVal(form) {
      form.addressInfo.prefecturesName = this.selectedAddress.address1;
      form.addressInfo.address = this.selectedAddress.address2;
      form.addressInfo.buildingName = this.selectedAddress.address3;

      this.targetForm = {};
    },

    /**
     * 住所検索押下（登録画面）
     */
    searchAddressHandlerInput(formId) {
      this.targetForm = this.createGenerateCompanyList.filter(
        (list) => list.formId === formId
      )[0];
      const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${this.targetForm.addressInfo.zipCode}`;

      axios
        .get(url, { adapter: axiosJsonpAdapter })
        .then((res) => {
          const results = res.data.results;

          if (results === null) {
            alert("該当住所が見つかりません、郵便番号をご確認ください。");
          } else if (results.length === 1) {
            // 単一住所のみヒット
            this.selectedAddress = results[0];
            this.updateFormValInput(this.targetForm);
          } else {
            // 復数住所のヒット
            this.addressResults = results;
            this.isAddressSelectModalShow = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // （登録画面）
    updateFormValInput(form) {
      form.addressInfo.prefecturesId = this.selectedAddress.address1;
      form.addressInfo.address = this.selectedAddress.address2;
      form.addressInfo.buildingName = this.selectedAddress.address3;

      this.targetForm = {};
    },

    selectAddressHandler(address) {
      this.selectedAddress = address;
      this.addressResults = [];
    },
  },
};
