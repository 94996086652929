import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {
      prefectures: [],
      contractGroupList: [],
      legalPersonalityTypes: [],
      contractTypes: [],
      invoiceTypes: [],
      duplicatedNameInfoList: [], //重複する名称情報
    };
  },

  methods: {
    // 排出事業者契約詳細取得API  GENERATOR_CONTRACT_INFO + {id}
    // 編集用
    getGeneratorContractInfoApi(contractId) {
      return axios
        .get(API_URL.GENERATOR.CONTRACTOR_INFO + contractId)
        .then((res) => {
          let data = res.data;
          data.generateCompanyInfoList = data.generateCompanyInfoList.map(
            (d, index) => {
              const newFormId = index + 1;
              d.formId = newFormId;

              d.addressInfo = {
                zipCode: d.zipCode,
                prefecturesId: d.prefecturesId,
                prefecturesName: d.prefecturesName,
                address: d.address,
                buildingName: d.buildingName,
              }
              var newValidateGenerateFactoryInfoList = [];
              d.generateFactoryInfoList = d.generateFactoryInfoList.map((f, findex) => {
                const newFactoryId = findex + 1;
                f.factoryId = newFactoryId;
                var newValidateGenerateStoreInfoList = [];
                f.generateStoreInfoList = f.generateStoreInfoList.map((s, sindex) => {
                  const newStoreId = sindex + 1;
                  s.storeId = newStoreId;
                  s.addressInfo = {
                    zipCode: s.zipCode,
                    prefecturesId: s.prefecturesId,
                    prefecturesName: s.prefecturesName,
                    address: s.address,
                    buildingName: s.buildingName,
                  }
                  newValidateGenerateStoreInfoList.push({
                    storeId: newStoreId,
                    name: { isValid: true, errMsg: "", isRequired: true },
                    zipCode: { isValid: true, errMsg: "", isRequired: true },
                    prefecture: { isValid: true, errMsg: "", isRequired: true },
                    address: { isValid: true, errMsg: "", isRequired: true },
                    tel: { isValid: true, errMsg: "", isRequired: true },
                    fax: { isValid: true, errMsg: "", isRequired: false },
                    autoEmptyReservePossessNumb: { isValid: true, errMsg: "", isRequired: false },
                  });
                  return s;
                });
                newValidateGenerateFactoryInfoList.push({
                  factoryId: newFactoryId, 
                  name: { isValid: true, errMsg: "", isRequired: true }, 
                  generateStoreInfoList: newValidateGenerateStoreInfoList,
                });
                return f;
              });
              const newValidate = {
                formId: newFormId,
                companyName: { isValid: true, errMsg: "" },
                zipCode: { isValid: true, errMsg: "" },
                prefecture: { isValid: true, errMsg: "" },
                address: { isValid: true, errMsg: "" },
                building: { isValid: true, errMsg: "" },
                tel: { isValid: true, errMsg: "" },
                fax: { isValid: true, errMsg: "" },
                jwnetContractNo: { isValid: true, errMsg: "" },
                ediKey: { isValid: true, errMsg: "" },
                generateFactoryInfoList: newValidateGenerateFactoryInfoList,
              };
              this.validate = [...this.validate, newValidate];

              return d;
            }
          );
          return data;
        })
        .then((data) => {
          console.log();
          this.form = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 都道府県選択肢取得API   DOMAIN.PREFECTURES
    getPrefecturesApi() {
      return axios
        .get(API_URL.DOMAIN.PREFECTURES)
        .then((res) => {
          this.prefectures = res.data.prefectures;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 所属による名称が重複チェックAPI
    checkDuplicationName(duplicationNameCheckInfoList) {
      return axios
          .post(API_URL.DUPLICATION.NAME, {duplicationNameCheckInfoList : duplicationNameCheckInfoList})
          .then((res) => {
            this.duplicatedNameInfoList = res.data.duplicatedNameInfoList;
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
};
